import { styled } from "@mui/material";
import { StyledBackground } from "../background.styled";
import { StyledComponentProps } from "../../../models/Types";

export const StyledHomeBackground = styled(StyledBackground)(
  ({ theme }: StyledComponentProps) => ({
    ".layer": {
      position: "absolute",
      width: "100%",
      height: "100vh",
      img: {
        objectFit: "contain",
        position: "absolute",
      },
    },

    ".layer0": {
      ".stoneL": {
        height: "20rem",
        left: "0",
        top: "25rem",
      },
      ".stoneC": {
        height: "40rem",
        left: "50%",
        transform: "translateX(-50%)",
        top: "7.5rem",
      },
      ".stoneR": {
        height: "25rem",
        right: "0",
        top: "15rem",
      },
    },
    ".layer1": {
      ".stepstonesL": {
        left: "0",
        top: "12.5rem",
        width: "40rem",
      },
      ".stepstonesR": {
        width: "25rem",
        right: "17.5rem",
        top: "6.5rem",
      },
    },
    ".layer2": {
      ".leafTl": {
        height: "20rem",
        top: "0",
      },
      ".leafL": {
        height: "20rem",
        left: "0",
        bottom: "20rem",
      },
      ".leafTr": {
        height: "20rem",
        right: "0",
        top: "5rem",
      },
    },
    ".layer3": {
      ".wave": {
        width: "100%",
        bottom: "0",
      },
    },
    ".layer4": {
      ".stoneL": {
        height: "15rem",
        left: "0",
        top: "30rem",
      },
      ".stoneC": {
        height: "35rem",
        left: "50%",
        transform: "translateX(-50%)",
        top: "10rem",
      },
      ".stoneR": {
        height: "20rem",
        right: "0",
        top: "17.5rem",
      },
    },
  })
);
