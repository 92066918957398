import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider, useTheme } from "@mui/material";
import { Home } from "./pages/home/Home";
import { dictionaryList, LanguageContext } from "./globals/LanguageContext";
import { useState } from "react";
import { theme } from "./globals/theme";
import { Navbar } from "./components/navbar/navbar";
export const App = () => {
  const [language, setLanguage] = useState("nl_BE");

  return (
    <>
      <LanguageContext.Provider
        value={{ language, dictionary: dictionaryList[language] }}
      >
        <ThemeProvider theme={theme}>
          <Router>
            <Navbar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="*" element={<></>} />
            </Routes>
          </Router>
        </ThemeProvider>
      </LanguageContext.Provider>
    </>
  );
};
