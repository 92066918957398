import { Box, styled } from "@mui/material";
import { StyledComponentProps } from "../../models/Types";

export const StyledHome = styled(Box)(({ theme }: StyledComponentProps) => ({
  ".spacer": {
    height: "37.5rem",
  },
  ".descriptionWrapper": {
    height: "15rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    h2: {
      color: theme.color.background,
      textAlign: "center",
      width: "40%",
      margin: "auto",
    },
  },
}));
