import { useContext } from "react";
import { LanguageContext } from "../globals/LanguageContext";
import { NavLink } from "../models/Types";

export const useNavLinks = () => {
  const { dictionary } = useContext(LanguageContext);

  const navLinks: NavLink[] = [
    {
      title: dictionary.pages.home.title,
      path: "/",
      isButton: false,
    },
    {
      title: dictionary.pages.aboutUs.title,
      path: "/aboutus",
      isButton: false,
    },
    {
      title: dictionary.pages.assortiment.title,
      path: "/assortiment",
      isButton: false,
    },
    {
      title: dictionary.pages.prices.title,
      path: "/prices",
      isButton: false,
    },
    {
      title: dictionary.pages.contact.title,
      path: "/contact",
      isButton: true,
    },
  ];

  return { navLinks };
};
