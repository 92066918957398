import { Box, Typography, useTheme } from "@mui/material";
import { StyledHome } from "./Home.styled";
import { LanguageContext } from "../../globals/LanguageContext";
import { useContext } from "react";
import logo from "../../images/decameleon_logo.png";
import stoneMainForeground from "../../images/Home/stone.main.foreground.v2.png";
import stoneLeftForeground from "../../images/Home/stone.left.foreground.png";
import { HomeBackground } from "../../components/background/homeBackground/homeBackground";

export const Home = () => {
  const { dictionary } = useContext(LanguageContext);
  const theme = useTheme();

  return (
    <StyledHome>
      <HomeBackground />
      <Box className="spacer" />
      <Box className="descriptionWrapper">
        <Typography variant="h2">
          {dictionary.pages.home.description}
        </Typography>
      </Box>
      <Box className="spacer" />
    </StyledHome>
  );
};
