import { Box, Button, Typography } from "@mui/material";
import { NavLink } from "../../../models/Types";
import { StyledNavLink } from "./navlink.styled";

type NavLinkProps = {
  navlink: NavLink;
};

export const NavLinkComponent = ({ navlink }: NavLinkProps) => {
  return (
    <StyledNavLink>
      {navlink.isButton ? (
        <Button>{navlink.title}</Button>
      ) : (
        <Box className="navButton">
          <Typography variant="h3" className="selected">
            {navlink.title}
          </Typography>
          <Box className="line selected" />
        </Box>
      )}
    </StyledNavLink>
  );
};
