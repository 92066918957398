import { Box, styled } from "@mui/material";
import { StyledComponentProps } from "../../models/Types";

export const StyledNavbar = styled(Box)(({ theme }: StyledComponentProps) => ({
  height: "7.5rem",
  position: "relative",
  paddingInline: "5rem",
  filter: `drop-shadow(3px 3px 3px ${theme.contrast.dark})`,
  zIndex: "99",
  ".navbar": {
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    justifyContent: "space-between",
    zIndex: "1",
    paddingTop: "1rem",
    dropShadow: `2px 2px 2px 2px${theme.contrast.lighter}`,
    ".logoWrapper": {
      display: "flex",
      position: "relative",
      width: "20rem",
      img: {
        width: "15rem",
        height: "5rem",
        objectFit: "contain",
      },
    },
    ".navlinks": {
      display: "flex",
      gap: "3rem",
      alignItems: "center",
    },
  },

  ".shape": {
    width: "100%",
    background: theme.color.background,
    position: "absolute",
    zIndex: "-1",
    clipPath: "ellipse(96% 14% at 50% 7%)",
    height: "35rem",
    top: "0",
    left: "0",
  },
}));
