import { ColorTheme } from "../models/ColorTheme";

export const defaultColors = {
  red: "#FF3737",
  orange: "#BF9000",
  green: "#548235",
};

export const themeColors: ColorTheme = {
  dark: "#262626",
  main: "#0D919F",
  lighter: "#11B7CA",
  light: "#C219A3",
  textColor: "#2E2E2E",
  contrastColor: "#8AC219",
  background: "#F7F7F7",
};

export const contrastColors = {
  dark: "#C0C0C0",
  main: "#E4E4E4",
  lighter: "#ECECEC",
  light: "#FFFFFF",
  shadowColor: "rgba(115,115,115,0.25)",
};
