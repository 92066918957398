import { Box, Typography } from "@mui/material";
import { StyledNavbar } from "./navbar.styled";
import logonavbar from "../../images/decameleon_navbar.png";
import { useContext } from "react";
import { LanguageContext } from "../../globals/LanguageContext";
import { useNavLinks } from "../../hooks/useNavLinks";
import { NavLinkComponent } from "./navlink/navlink";
import navbarBackground from "../../images/navbar.background.png";

export const Navbar = () => {
  const { dictionary } = useContext(LanguageContext);
  const { navLinks } = useNavLinks();

  return (
    <StyledNavbar>
      <Box className="shape" />
      <Box className="navbar">
        <Box className="logoWrapper">
          <img src={logonavbar} />
        </Box>
        <Box className="navlinks">
          {navLinks.map((navlink) => (
            <NavLinkComponent navlink={navlink} />
          ))}
        </Box>
      </Box>
    </StyledNavbar>
  );
};
