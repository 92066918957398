import { Box, styled } from "@mui/material";
import { StyledComponentProps } from "../../../models/Types";

export const StyledNavLink = styled(Box)(({ theme }: StyledComponentProps) => ({
  button: {
    background: theme.color.contrastColor,
    "&:hover": {
      background: theme.color.lighter,
    },
  },
  ".navButton": {
    position: "relative",
    h3: {
      fontWeight: "bold",
      color: theme.color.textColor,
      padding: "0",
      height: "min-content",
      margin: "0",
      lineHeight: "30px",
      cursor: "pointer",
      fontSize: "1.25rem",
    },
    ".line": {
      width: "100%",
      height: "3px",
      background: theme.color.light,
      position: "absolute",
      opacity: "0",
      transition: ".5s",
    },
    "&:hover": {
      color: theme.contrast.main,
      ".line": {
        opacity: "1",
      },
    },
  },

  ".selected": {},
}));
