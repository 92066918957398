import { Box, styled } from "@mui/material";
import { StyledComponentProps } from "../../models/Types";

export const StyledBackground = styled(Box)(
  ({ theme }: StyledComponentProps) => ({
    width: "100%",
    position: "absolute",
    zIndex: "-1",
    top: "0",
  })
);
