import { Box } from "@mui/material";
import l0stonel from "../../../images/Home/home.layer0.stoneleft.svg";
import l0stonec from "../../../images/Home/home.layer0.stonecenter.svg";
import l0stoner from "../../../images/Home/home.layer0.stoneright.svg";
import l1stepstonesl from "../../../images/Home/home.layer1.stepstonesleft.svg";
import l1stepstonesr from "../../../images/Home/home.layer1.stepstonesright.svg";
import l2leaftl from "../../../images/Home/home.layer2.leaftopleft.svg";
import l2leafl from "../../../images/Home/home.layer2.leafleft.svg";
import l2leaftr from "../../../images/Home/home.layer2.leaftopright.svg";
import l3wave from "../../../images/Home/home.layer3.wave.svg";
import l4stonel from "../../../images/Home/home.layer4.stoneleft.svg";
import l4stonec from "../../../images/Home/home.layer4.stonecenter.svg";
import l4stoner from "../../../images/Home/home.layer4.stoneright.svg";

import { StyledHomeBackground } from "./homeBackground.styled";

export const HomeBackground = () => {
  return (
    <StyledHomeBackground>
      <Box className="layer layer0" id="stonesbackground">
        <img src={l0stonel} className="stoneL" />
        <img src={l0stonec} className="stoneC" />
        <img src={l0stoner} className="stoneR" />
      </Box>
      <Box className="layer layer1" id="stepstonesbackground">
        <img src={l1stepstonesl} className="stepstonesL" />
        <img src={l1stepstonesr} className="stepstonesR" />
      </Box>
      <Box className="layer layer2" id="leafbackground">
        <img src={l2leaftl} className="leafTl" />
        <img src={l2leafl} className="leafL" />
        <img src={l2leaftr} className="leafTr" />
      </Box>
      <Box className="layer layer3" id="wavebackground">
        <img src={l3wave} className="wave" />
      </Box>
      <Box className="layer layer4" id="stonesforeground">
        <img src={l4stonel} className="stoneL" />
        <img src={l4stonec} className="stoneC" />
        <img src={l4stoner} className="stoneR" />
      </Box>
    </StyledHomeBackground>
  );
};
